/**
 * Device 新增
 * @type {{}}
 */
import Vue from "vue";
import {
    Message,
    Modal,
    Breadcrumb,
    BreadcrumbItem,
    Select,
    Option,
    Input,
    Icon,
    Button,
    Cascader,
    Progress,
    Tooltip
} from "view-design";
import {
    getDeviceModelService,
    getManufacturerService,
    addManufacturerService,
    getNameManufacturerService,
    addDeviceModelService,
    addDeviceInfoService,
    deleteDeviceModelService,
    deleteManufacturerService,
    editDeviceModelService,
    editManufacturerService
} from "@/service/device-info-service";
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('BreadcrumbItem', BreadcrumbItem);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Button', Button);
Vue.component('Cascader', Cascader);
Vue.component('Modal', Modal);
Vue.component('Progress', Progress);
Vue.component('Tooltip', Tooltip);
import { mapMutations, mapState } from "vuex";
import { onSysncUploadFiles } from "@/utils/request-function";
import { systemUploadType } from "@/service/system-service";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import ViewImage from '@/components/ViewImage/viewImage.vue'
const NewlyAdded = {
    name:'NewlyAdded',
    components:{ ViewImage },
    computed: {
        ...mapState({
            classifyMenuArray: state => state.system.classifyMenuArray, //分类菜单数据
            selectedMenuId: state => state.system.selectedMenuId, //选中的菜单Id
        }),
    },
    data() {
        return {
            submitLoading:false, //提交等待
            deviceModelVisible:false, //新增设备型号可显示
            manufacturerVisible:false, //新增设备厂家可显示
            imageSrc:'', //上传缩略图
            complete:0, //上传进度
            imageName:'',
            viewImageVisible:false,
            fileHandleVisible:false, //上传操作弹框可显示
            speedSingleVisible:false, //单文件上产进度可显示
            deviceModelArray:[], //获取所有设备型号
            manufacturerArray:[], //获取所有生产厂家
            deviceModelManufacturerArray:[], //根据设备型号拉取的生产厂家
            deviceModelValue:'', //查询设备型号
            deviceFactureValue:'', //查询生产厂家
            deviceModelInfo:'',
            deviceFactureInfo:'',
            deviceBtnVisible:false, //设备按钮状态
            deviceAddVisible:false, //新增数据状态
            deviceFactureSelect:[], //选中的上产厂家
            deviceModelSelect:[], //选中的设备型号
            newlyParams:{  //保存数据集
                deviceType:[], //选择的设备分类
                deviceName:'', //设备名称
                deviceModel:'', //选择的设备型号
                manufacturer:'', //选择的生产厂家
                files:'' //选择的图片流
            },
            deviceParams:{ //新增设备型号数据集
                deviceName:'', //设备型号名称
                manufacturer:[], //生产厂家
            },
            manufacturerParams:{ //新增生产厂家数据集
                manufacturerName:'',
            },
        }
    },
    created() {
        this.onDeviceModelAll().then()
        this.onManufacturerAll().then()
        /**
         * 进度条数值
         */
        $event.$on(EVENT_NAME.UPLOAD_PROGRESS_COMPLETE, (n) => {
            this.complete = n
        })
    },
    methods: {
        ...mapMutations('system',['SET_DEVICE_INFO','SET_TAB_ID']),
        /**
         * 返回
         */
        onBack() {
            this.$router.push('/device/list')
        },
        /**
         * 新增设备型号验证
         */
        onDeviceModelCheck(){
            if(this.deviceParams.deviceName === ''){
                Message.error('请输入设备型号！')
                return
            }
            // if(this.deviceParams.manufacturer.length === 0){
            //     Message.error('请选择生产厂家！')
            //     return
            // }
            this.onDeviceModel().then()
        },
        /**
         * 新增生产厂家验证
         */
        onManufacturerCheck(){
            if(this.manufacturerParams.manufacturerName === ''){
                Message.error('请输入您要添加的生产厂家！')
                return
            }
            this.onAddManufacturer().then()
        },
        /**
         * 点击型号弹框关闭状态
         * @param status
         */
        onModelVisibleChange(status){
            if(!status){
                this.onDeviceModalResetting()
                this.deviceParams.deviceName = ''
                this.deviceAddVisible = false
            }
        },
        /**
         * 点击厂家弹框关闭状态
         * @param status
         */
        onFactureVisibleChange(status){
            if(!status){
                this.onDeviceFactureResetting()
                this.manufacturerParams.manufacturerName= ''
                this.deviceAddVisible = false
            }
        },
        /**
         * 型号重置
         */
        onDeviceModalResetting() {
            this.deviceModelValue = ''
            this.deviceModelSelect = this.deviceModelArray
            for(let i in this.deviceModelSelect){
                this.deviceModelSelect[i].visible = true
            }
            this.deviceBtnVisible = false
            this.$forceUpdate()
        },
        /**
         * 厂家重置
         */
        onDeviceFactureResetting() {
            this.deviceFactureValue = ''
            this.deviceFactureSelect = this.manufacturerArray
            for(let i in this.deviceFactureSelect){
                this.deviceFactureSelect[i].visible = true
            }
            this.deviceBtnVisible = false
            this.$forceUpdate()
        },
        /**
         * 搜索型号
         */
        onDeviceModalSearch() {
            if(this.deviceModelValue === '') return
            this.deviceModelSelect = this.onFilter(this.deviceModelArray,this.deviceModelValue)
            this.deviceBtnVisible = true
            this.deviceAddVisible = false
            this.deviceParams.deviceName = ''
        },
        /**
         * 搜索厂家
         */
        onDeviceFactureSearch() {
            if(this.deviceFactureValue === '') return
            this.deviceFactureSelect = this.onFilter(this.manufacturerArray,this.deviceFactureValue)
            this.deviceBtnVisible = true
            this.deviceAddVisible = false
            this.manufacturerParams.manufacturerName= ''
        },
        /**
         * 删除设备型号
         * @param key
         */
        onDeviceModalDel(key) {
            this.onDeviceModelDelete(this.deviceModelSelect[key].id).then()
        },
        /**
         * 删除设备厂家
         * @param key
         */
        onDeviceFactureDel(key) {
            this.onDeviceFactureDelete(this.deviceFactureSelect[key].id).then()
        },
        /**
         * 过滤列表相同名称
         * @param array
         * @param content
         * @returns {*[]}
         */
        onFilter(array,content){
            return array.filter((value)=>{  //过滤数组元素
                return value.name.includes(content); //如果包含字符返回true
            });
        },
        onDeviceModalEdit(key){
            for(let i in this.deviceModelSelect){
                this.deviceModelSelect[i].visible = true
            }
            this.deviceModelInfo = this.deviceModelSelect[key].name
            this.deviceModelSelect[key].visible? this.deviceModelSelect[key].visible = false : this.deviceModelSelect[key].visible = true
            this.deviceAddVisible = false
            this.deviceParams.deviceName = ''
            this.$forceUpdate()
        },
        onDeviceFactureEdit(key){
            for(let i in this.deviceFactureSelect){
                this.deviceFactureSelect[i].visible = true
            }
            this.deviceFactureInfo = this.deviceFactureSelect[key].name
            this.deviceFactureSelect[key].visible? this.deviceFactureSelect[key].visible = false : this.deviceFactureSelect[key].visible = true
            this.deviceAddVisible = false
            this.manufacturerParams.manufacturerName= ''
            this.$forceUpdate()
        },
        onDeviceModalClose(key){
            this.deviceModelSelect[key].visible? this.deviceModelSelect[key].visible = false : this.deviceModelSelect[key].visible = true
            this.deviceModelInfo = ''
            this.$forceUpdate()
        },
        onDeviceFactureClose(key){
            this.deviceFactureSelect[key].visible? this.deviceFactureSelect[key].visible = false : this.deviceFactureSelect[key].visible = true
            this.deviceFactureInfo = ''
            this.$forceUpdate()
        },
        onDeviceModelEditCheck(key) {
            if(this.deviceModelInfo === ''){
                Message.warning('请输入设备型号')
                return
            }
            if(this.deviceModelSelect.some((item)=>{ return item.name === this.deviceModelInfo})){
                Message.warning('信息重复，请重新输入！')
                return
            }
            this.onDeviceModelEdit(this.deviceModelSelect[key].id).then()
        },
        onDeviceFactureEditCheck(key) {
            if(this.deviceFactureInfo === ''){
                Message.warning('请输入生产厂家')
                return
            }
            if(this.deviceFactureSelect.some((item)=>{ return item.name === this.deviceFactureInfo})){
                Message.warning('信息重复，请重新输入！')
                return
            }
            this.onDeviceFactureEditService(this.deviceFactureSelect[key].id).then()
        },
        /**
         * 新增型号
         */
        onDeviceAddModel() {
            this.deviceAddVisible = true
            for(let i in this.deviceModelSelect){
                this.deviceModelSelect[i].visible = true
            }
            this.deviceModelInfo = ''
            this.$nextTick(()=>{
                let deviceList = document.getElementById('deviceList')
                deviceList.scrollTop = (this.deviceModelArray.length+1)*42-273
            })
        },
        /**
         * 新增厂家
         */
        onDeviceAddFacture() {
            this.deviceAddVisible = true
            for(let i in this.deviceFactureSelect){
                this.deviceFactureSelect[i].visible = true
            }
            this.deviceFactureInfo = ''
            this.$nextTick(()=>{
                let deviceList = document.getElementById('deviceFactureList')
                deviceList.scrollTop = (this.manufacturerArray.length+1)*42-273
            })
        },
        /**
         * 清空
         */
        onClearImage() {
            this.imageSrc = '';
            this.$refs.pathClear.value = ''
            this.newlyParams.files = ''
        },
        /**
         * 图片放大
         */
        onImageAmplify() {
            this.imageName = '设备图片'
            this.viewImageVisible = true
        },
        /**
         * 替换属性名
         * @param data
         */
        onReplaceAttribute(data){
            let item = []
            data.map(list => {
                let newData = {}
                newData.value = list.id;
                newData.label = list.name;
                newData.children = list.childNode;
                if(list.childNode){
                    newData.children = this.onReplaceAttribute(list.childNode)
                }
                item.push(newData)
            })
            return item
        },
        /**
         * 新增生产厂家验证
         */
        onDeviceFactureCheck(){
            if(this.manufacturerParams.manufacturerName === ''){
                Message.warning('请输入生产厂家！')
                return
            }
            if(this.deviceFactureSelect.some((item)=>{ return item.name === this.manufacturerParams.manufacturerName })){
                Message.warning('信息重复，请重新输入！')
                return
            }
            // if(this.deviceParams.manufacturer.length === 0){
            //     Message.error('请选择生产厂家！')
            //     return
            // }
            this.onAddManufacturer().then()
        },
        /**
         * 上传文件
         * @param file
         */
        onFileChange(event) {
            const file =  event.target.files[0];
            this.fileType = file.type
            if (!['image/png', 'image/jpg', 'image/gif', 'image/jpeg'].includes(this.fileType)) {
                Message.error('请上传正确的图片')
                return
            }
            if (file.size > 5 * 1024 * 1024) {
                Message.error('请选择5M以内的图片')
                return
            }
            this.speedSingleVisible = true
            onSysncUploadFiles([file],systemUploadType.knowledge_image).then((data)=>{
                this.speedSingleVisible = false
                this.imageSrc = data[0].url
                this.newlyParams.files = data[0].resourceName
                this.complete = 0
            }).catch(()=>{
                this.speedSingleVisible = false
                this.complete = 0
                this.onClearImage()
                Message.error('上传失败，请重新尝试！')
            })
        },
        /**
         * 新增数据验证
         */
        onAddRecordCheck() {
            if(this.newlyParams.deviceType.length === 0){
                Message.error('请选择设备分类！')
                return;
            }
            if(this.newlyParams.deviceName === ''){
                Message.error('请输入设备名称！')
                return;
            }
            if(this.newlyParams.deviceModel === ''){
                Message.error('请选择设备型号！')
                return;
            }
            if(this.newlyParams.manufacturer === ''){
                Message.error('请选择生产厂家！')
                return;
            }
            this.onAddDeviceInfo().then()
        },
        /**
         * 获取所有设备型号
         */
        async onDeviceModelAll() {
            try {
                const { code,data } = await getDeviceModelService()
                if(code === 'SUCCESS'){
                    this.deviceModelArray = data
                    this.deviceModelSelect = data
                    for(let i in this.deviceModelSelect){
                        this.deviceModelSelect[i].visible = true
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取所有生产厂家
         */
        async onManufacturerAll() {
            try {
                const { code,data } = await getManufacturerService()
                if(code === 'SUCCESS'){
                    this.manufacturerArray = data
                    this.deviceFactureSelect = data
                    for(let i in this.deviceFactureSelect){
                        this.deviceFactureSelect[i].visible = true
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 根据设备型号名称查询生产厂家
         */
        async onNameManufacturer() {
            try {
                let params = {
                    name:this.deviceParams.deviceName
                }
                const { code,data } = await getNameManufacturerService(params)
                if(code === 'SUCCESS'){
                    let list = []
                    for(let i in data){
                        list.push(data[i].id)
                    }
                    this.deviceParams.manufacturer = list
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 添加设备型号
         */
        async onDeviceModel() {
            try {
                this.submitLoading = true
                let params = {
                    modelName:this.deviceParams.deviceName,
                    // manufacturerIds:this.deviceParams.manufacturer
                }
                const { code } = await addDeviceModelService(params)
                if(code === 'SUCCESS'){
                    Message.success('添加成功')
                    this.deviceAddVisible = false
                    this.deviceParams.deviceName = ''
                    this.deviceParams.manufacturer = []
                    this.deviceModelManufacturerArray = []
                    this.onDeviceModelAll().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 添加生产厂家
         */
        async onAddManufacturer() {
            try {
                this.submitLoading = true
                let params = {
                    name:this.manufacturerParams.manufacturerName
                }
                const { code } = await addManufacturerService(params)
                if(code === 'SUCCESS'){
                    Message.success('添加成功')
                    this.deviceAddVisible = false
                    this.manufacturerParams.manufacturerName = ''
                    this.onManufacturerAll().then()
                }else if(code === 'EQUIPMENT_MANUFACTURER_REPEAT_NAME'){
                    Message.error('添加的生产厂家重复！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 添加设备信息
         */
        async onAddDeviceInfo() {
            try {
                this.submitLoading = true
                let deviceType
                if(this.newlyParams.deviceType.length === 1){
                    deviceType = this.newlyParams.deviceType[0]
                }
                if(this.newlyParams.deviceType.length === 2){
                    deviceType = this.newlyParams.deviceType[1]
                }
                if(this.newlyParams.deviceType.length === 3){
                    deviceType = this.newlyParams.deviceType[2]
                }
                let params = {
                    equipmentClassificationId:deviceType,
                    name:this.newlyParams.deviceName,
                    modelId:this.newlyParams.deviceModel,
                    manufacturerId:this.newlyParams.manufacturer,
                    picturesUrl:this.newlyParams.files
                }
                const { code,data } = await addDeviceInfoService(params)
                if(code === 'SUCCESS'){
                    Message.success('添加成功')
                    this.SET_DEVICE_INFO(data)
                    this.SET_TAB_ID(4)
                    this.$router.push('/device/info')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 删除设备型号
         */
        async onDeviceModelDelete(id) {
            try {
                this.submitLoading = true
                let params = {
                    modelId:id,
                    // manufacturerIds:this.deviceParams.manufacturer
                }
                const { code } = await deleteDeviceModelService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功')
                    this.onDeviceModelAll().then()
                }else if(code === 'EQUIPMENT_MANUFACTURER_MODEL_DELETE_FAIL_LINKED_DATA_EXISTS'){
                    Message.error('此型号已被引用，不可删除！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 删除设备厂家
         */
        async onDeviceFactureDelete(id) {
            try {
                this.submitLoading = true
                let params = {
                    manufacturerId:id,
                    // manufacturerIds:this.deviceParams.manufacturer
                }
                const { code } = await deleteManufacturerService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功')
                    this.onManufacturerAll().then()
                }else if(code === 'EQUIPMENT_MANUFACTURER_DELETE_FAIL_LINKED_DATA_EXISTS'){
                    Message.error('此厂家已被引用，不可删除！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 编辑设备型号
         */
        async onDeviceModelEdit(id) {
            try {
                this.submitLoading = true
                let params = {
                    modelName:this.deviceModelInfo,
                    modelId:id,
                    // manufacturerIds:this.deviceParams.manufacturer
                }
                const { code } = await editDeviceModelService(params)
                if(code === 'SUCCESS'){
                    Message.success('修改成功')
                    this.deviceModelInfo = ''
                    this.onDeviceModalResetting()
                    this.onDeviceModelAll().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
        /**
         * 编辑设备型号
         */
        async onDeviceFactureEditService(id) {
            try {
                this.submitLoading = true
                let params = {
                    name:this.deviceFactureInfo,
                    manufacturerId:id,
                    // manufacturerIds:this.deviceParams.manufacturer
                }
                const { code } = await editManufacturerService(params)
                if(code === 'SUCCESS'){
                    Message.success('修改成功')
                    this.deviceFactureInfo = ''
                    this.onDeviceFactureResetting()
                    this.onManufacturerAll().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.submitLoading = false
            }catch (error){
                this.submitLoading = false
                console.log(error)
            }
        },
    },
    destroyed () {
        $event.$off([EVENT_NAME.UPLOAD_PROGRESS_COMPLETE])
    }
}
export default NewlyAdded
